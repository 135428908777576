import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeadingBackground from "../images/background_heading.jpg";

function ContactPage() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "background_contact.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Contactez-nous" />

      <div
        className="mb-10 bg-opacity-50 h-64 bg-cover bg-no-repeat bg-center flex items-center content-center justify-center"
        style={{ backgroundImage: `url(${HeadingBackground})` }}
      >
        <h1 className="text-white text-6xl">Contactez-nous</h1>
      </div>
      <section>
        <div className="relative bg-white">
          <div className="hidden lg:block lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <Img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                fluid={data.file.childImageSharp.fluid}
                alt="Contactez-nous"
              />
            </div>
          </div>
          <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
            <div className="lg:pr-8">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                  Travaillons ensemble
                </h2>
                <p className="mt-4 text-lg leading-7 text-gray-500 sm:mt-3">
                  Pour toutes vos demandes de renseignements ou de devis, vous
                  pouvez utiliser le formulaire ci-dessous. Nous reviendrons
                  trés vite vers vous.
                </p>
                <form
                  action="/succes/"
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  className="mt-9 grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
                >
                  <div className="hidden">
                    <label>
                      Ne pas remplir <input name="bot-field" />
                    </label>
                  </div>
                  <input type="hidden" name="form-name" value="contact" />
                  <div>
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Nom
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="last_name"
                        name="nom"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Prénom
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="first_name"
                        name="prenom"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      E-mail
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="email"
                        type="email"
                        name="email"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Société
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="company"
                        name="societe"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Téléphone
                      </label>
                    </div>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="phone"
                        name="telephone"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="how_can_we_help"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Comment pouvons nous vous aider?
                      </label>
                    </div>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <textarea
                        id="how_can_we_help"
                        name="message"
                        rows="4"
                        className="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="text-right sm:col-span-2">
                    <span className="inline-flex rounded-md shadow-sm">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700 transition duration-150 ease-in-out"
                      >
                        Envoyer
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
